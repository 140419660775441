<template>
  <div class="official__information">
    <div class="official__copyright">ООО «Медсервис», {{ currentYear }}</div>
    <div class="official__description">
      <span>ИНН 5959001888</span>
      <span>ОГРН 1185958026100</span>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss" scoped>
.official__information {
  z-index: 3;
  width: 100%;
  // position: absolute;
  // left: 0;
  // bottom: 0;
  margin-top: auto;
  padding: 10px 64px 16px 64px;
  grid-column: span 2;
  border-top: 1px solid rgba(0, 0, 39, 0.1);
  background: #e2f9ff;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 24px;

  @media (max-width: 768px) {
    display: flex;
    flex-flow: nowrap row;
    justify-content: space-between;
  }

  @media (max-width: 576px) {
    flex-flow: nowrap column;
    padding: 16px 16px 16px 16px;
    gap: 24px;
    position: relative;
  }

  .official__description {
    display: flex;
    flex-flow: wrap row;
    gap: 48px;

    @media (max-width: 768px) {
      gap: 24px;
    }

    @media (max-width: 576px) {
      flex-flow: nowrap column;
      gap: 8px;
    }
  }
}
</style>
