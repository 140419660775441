<template>
  <div class="analysis-item">
    <button class="analysis-item__close" @click="removeItem">
      <img
        src="@/assets/img/close_24x24.svg"
        alt="close_24x24"
        :style="{ display: removeAnalysis }"
      />
    </button>
    <div class="analysis-item__info">
      <h2 class="analysis-item__title">{{ analysis.Analysis }}</h2>
      <div class="analysis__pricing">
        <p class="analysis-item__price">
          <span>{{ analysis.Price }}&thinsp;₽</span>
          <img src="@/assets/img/thunder_14x14.svg" alt="thunder_14x14" />
        </p>
        <p class="analysis-item__time">Результаты через день</p>
      </div>
    </div>
    <button
      class="analysis-item__button"
      :class="{ 'analysis-item__button--added': isAdded }"
      :style="{ display: button }"
      @click="toggleOrder"
    >
      {{ isAdded ? "Добавлен в корзину" : "Добавить в заказ" }}
    </button>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";

export default {
  name: "AnalysisItem",
  props: {
    analysis: {
      type: Object,
      required: true,
    },
    button: {
      type: String,
      default: "block",
    },
    removeAnalysis: {
      type: String,
      default: "none",
    },
  },
  setup(props, { emit }) {
    const isAdded = ref(false);

    onMounted(() => {
      const savedAnalyses = JSON.parse(
        localStorage.getItem("savedAnalyses") || "[]"
      );
      isAdded.value = savedAnalyses.some(
        (item) => item.Code === props.analysis.Code
      );
    });

    const toggleOrder = () => {
      isAdded.value = !isAdded.value;
      let savedAnalyses = JSON.parse(
        localStorage.getItem("savedAnalyses") || "[]"
      );
      if (isAdded.value) {
        savedAnalyses.push(props.analysis);
      } else {
        savedAnalyses = savedAnalyses.filter(
          (item) => item.Code !== props.analysis.Code
        );
      }
      localStorage.setItem("savedAnalyses", JSON.stringify(savedAnalyses));
      emit("update:analysisData", savedAnalyses);
    };

    const removeItem = () => {
      let savedAnalyses = JSON.parse(
        localStorage.getItem("savedAnalyses") || "[]"
      );
      savedAnalyses = savedAnalyses.filter(
        (item) => item.Code !== props.analysis.Code
      );
      localStorage.setItem("savedAnalyses", JSON.stringify(savedAnalyses));
      emit("update:analysisData", savedAnalyses);
    };

    return {
      isAdded,
      toggleOrder,
      removeItem,
    };
  },
};
</script>

<style lang="scss" scoped>
.analysis-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 32px 24px;
  border-radius: 12px;
  border: 1px solid #e2e7ec;
  background: #fff;

  @media (max-width: 768px) {
    align-items: flex-start;
  }

  @media (max-width: 576px) {
    flex-flow: nowrap column;
    padding: 24px 16px;
    gap: 32px;
  }
}

.analysis-item__close {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  opacity: 0.2;
  transition: all 0.2s ease-in-out;

  @media (max-width: 567px) {
    top: 12px;
    right: 12px;
  }

  &:hover {
    opacity: 0.6;
  }

  img {
    @media (max-width: 567px) {
      height: 16px;
    }
  }
}

.analysis-item__info {
  flex-grow: 1;

  .analysis-item__title {
    max-width: 880px;
    margin: 0;
    color: #000027;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.48px;
    margin-bottom: 16px;

    @media (max-width: 576px) {
      font-size: 20px;
    }
  }

  .analysis__pricing {
    display: flex;
    flex-flow: nowrap row;
    align-items: flex-end;
    gap: 8px;

    .analysis-item__price {
      display: flex;
      flex-flow: nowrap row;
      align-items: flex-start;
      gap: 2px;
      color: #0c7be5;
      font-family: "Inter";
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.48px;

      @media (max-width: 576px) {
        font-size: 20px;
      }

      img {
        @media (max-width: 576px) {
          height: 12px;
        }
      }
    }

    .analysis-item__time {
      overflow: hidden;
      color: #000027;
      text-overflow: ellipsis;
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.32px;
      opacity: 0.4;

      @media (max-width: 576px) {
        font-size: 14px;
      }
    }
  }
}

.analysis-item__button {
  display: flex;
  padding: 14px 24px 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #e2e7ec;
  background-color: #fff;
  color: #000027;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.16px;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;

  @media (max-width: 576px) {
    width: 100%;
    padding: 14px 24px 16px 24px;
    font-size: 16px;
  }

  &:hover {
    background-color: #f5f6f7;
  }

  &:active {
    background-color: #ecf1f6;
  }
}

.analysis-item__button--added {
  background-color: #ecf1f6;
}

.analysis-item__button--added:hover {
  background-color: #f5f6f7;
}
</style>
