<template>
  <div class="checkout__stub">
    <p>Ваша корзина пуста</p>
    <img
      src="@/assets/img/analysis__placeholder.jpg"
      alt="analysis__placeholder"
    />
  </div>
</template>

<script>
export default {
  name: "CheckoutStub",
};
</script>

<style lang="scss" scoped>
.checkout__stub {
  overflow: hidden;
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  color: #000027;
  opacity: 0.4;

  p {
    margin-bottom: 40px;
  }

  img {
    width: 100%;
  }
}
</style>
