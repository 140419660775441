<template>
  <div class="checkout__wrapper">
    <div class="container">
      <div class="checkout__section">
        <div class="checkout__details">
          <div class="checkout__heading">
            <h1>Корзина</h1>
            <button @click="clearCart">Очистить корзину</button>
          </div>
          <div class="checkout__items">
            <template v-if="analysisData.length > 0">
              <AnalysisItem
                v-for="(analysis, index) in analysisData"
                :key="index"
                :analysis="analysis"
                button="none"
                removeAnalysis="block"
                @update:analysisData="updateAnalysisData"
              />
            </template>
            <template v-else>
              <CheckoutStub />
            </template>
          </div>
        </div>
        <div class="checkout__summary" v-if="analysisData.length > 0">
          <div class="checkout__summary-block">
            <div class="summary__caption">К оплате</div>
            <div class="summary__price">{{ totalPrice }}&thinsp;₽</div>
            <router-link to="">Перейти к оформлению</router-link>
          </div>
        </div>
      </div>
    </div>
    <Legal />
  </div>
</template>

<script>
import AnalysisItem from "@/components/AnalysisItem.vue";
import { ref, computed, onMounted } from "vue";
import Legal from "../components/Legal.vue";
import CheckoutStub from "../components/CheckoutStub.vue";

export default {
  components: {
    AnalysisItem,
    Legal,
    CheckoutStub,
  },
  setup() {
    const analysisData = ref([]);

    onMounted(() => {
      const savedAnalyses = JSON.parse(
        localStorage.getItem("savedAnalyses") || "[]"
      );
      analysisData.value = savedAnalyses;
    });

    const clearCart = () => {
      localStorage.removeItem("savedAnalyses");
      analysisData.value = [];
    };

    const updateAnalysisData = (newData) => {
      analysisData.value = newData;
    };

    const totalPrice = computed(() => {
      return analysisData.value.reduce(
        (sum, analysis) => sum + analysis.Price,
        0
      );
    });

    return {
      analysisData,
      totalPrice,
      clearCart,
      updateAnalysisData,
    };
  },
};
</script>

<style lang="scss" scoped>
.checkout__wrapper {
  display: flex;
  flex-flow: nowrap column;
  height: 100vh;
}

.checkout__section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 40px 0;
  gap: 16px;

  @media (max-width: 768px) {
    padding-top: 80px;
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 576px) {
    padding-top: 32px;
    gap: 24px;
  }

  .checkout__details {
    grid-column: span 2;

    @media (max-width: 768px) {
      grid-column: auto;
    }

    .checkout__heading {
      display: flex;
      flex-flow: nowrap row;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 24px;
      margin-right: 16px;

      @media (max-width: 576px) {
        margin-bottom: 16px;
        margin-right: 8px;
      }

      h1 {
        color: #000027;
        font-family: "Inter";
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 48px */
        letter-spacing: -0.8px;

        @media (max-width: 576px) {
          font-size: 24px;
        }
      }

      button {
        cursor: pointer;
        border: none;
        background: none;
        color: #000027;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.32px;
        opacity: 0.4;
        transition: all 0.2s ease-in-out;

        @media (max-width: 576px) {
          font-size: 14px;
        }

        &:hover,
        :active {
          opacity: 0.6;
        }
      }
    }

    .checkout__items {
      display: flex;
      flex-flow: nowrap column;
      gap: 16px;
    }
  }

  .checkout__summary-block {
    margin-top: 72px;
    display: flex;
    flex-flow: nowrap column;
    padding: 24px 24px 32px 24px;
    border-radius: 12px;
    border: 1px solid #e2e7ec;
    background: #f1f7f7;

    @media (max-width: 768px) {
      margin-top: 24px;
    }

    @media (max-width: 576px) {
      margin-top: auto;
      padding: 16px 16px 24px 16px;
    }

    .summary__caption {
      color: #000027;
      font-family: "Inter";
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.4px;
      margin-bottom: 12px;

      @media (max-width: 576px) {
        font-size: 16px;
      }
    }

    .summary__price {
      color: #000027;
      font-family: "Inter";
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.8px;
      margin-bottom: 32px;

      @media (max-width: 576px) {
        font-size: 32px;
      }
    }

    a {
      text-align: center;
      padding: 18px 0px 19px 0px;
      color: #fff;
      font-family: "Inter";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.36px;
      border-radius: 10px;
      background-color: #0c7be5;

      @media (max-width: 576px) {
        font-size: 16px;
        padding: 16px 0px 17px 0px;
      }
    }
  }
}
</style>
